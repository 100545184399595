import * as React from "react";
import * as styles from "./MdxImgFrame.module.scss";

const MdxImgFrame = ({ imgWidth, children }) => {
  return (
    <div className={styles.mdx__imgframe} style={{ width: imgWidth ? `${imgWidth}%` : `100%` }}>
      {children}
    </div>
  );
};

export default MdxImgFrame;
