import * as React from "react";
import { Link } from "gatsby";

import * as styles from "./Breadcrumb.module.scss";

// Config Object Format
//
// const objectLinks = {
//   trail: [
//     {
//       name: "Home",
//       url: "/",
//     },
//     {
//       name: "All Writing & Notes",
//       url: "/writing",
//     },
//   ],
//   current: {
//     name: "Hello Test",
//   },
// };

const Breadcrumb = ({ config }) => {
  const crumbs = config.trail.map((link) => {
    return (
      <li key={link.name}>
        <Link to={link.url}>{link.name}</Link>
        <span>/</span>
      </li>
    );
  });

  return (
    <nav className={styles.breadcrumb}>
      <ol className={styles.breadcrumb_lists}>
        {crumbs}
        <li key={config.current.name}>{config.current.name}</li>
      </ol>
    </nav>
  );
};

export default Breadcrumb;
