import * as React from "react";
import { graphql } from "gatsby";

import Breadcrumb from "../components/Breadcrumb/Breadcrumb";

import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import MdxImgFrame from "../components/MDX/MdxImgFrame/MdxImgFrame";
import MdxGrid from "../components/MDX/MdxGrid/MdxGrid";
import { CodeInline } from "../components/Code/Code";

import * as mdxStyles from "../styles/pages/templates/MdxTemplate.module.scss";
import * as styles from "../styles/pages/templates/templateGrokkingTechQns.module.scss";

const DailyTechQuestionsPage = ({ data }) => {
  const { frontmatter, body } = data.mdx;
  const { title, source, difficulty, challengeLink, description } = frontmatter;

  const matchedSource = getSource(data, source);
  const sourceImage = getImage(matchedSource.icon);

  const crumbs = {
    trail: [
      {
        name: "All Works",
        url: "/work",
      },
      {
        name: "Grokking Technical Questions",
        url: "/work/grokking-technical-questions",
      },
    ],
    current: {
      name: title,
    },
  };

  return (
    <div className={styles.content_wrapper}>
      <Breadcrumb config={crumbs} />
      <h1>{data.mdx.frontmatter.title}</h1>
      <p>{description}</p>
      <div className={styles.meta_grid}>
        <MetaSource source={matchedSource} image={sourceImage} url={challengeLink} />
        <MetaDifficulty difficulty={difficulty} />
        <MetaDuration />
      </div>
      {/* <div className={styles.tags_grid}>
        <p>Tags:</p>
      </div> */}
      <div className={styles.divider}></div>
      <article className={mdxStyles.mdx_content}>
        <MDXProvider components={{ MdxImgFrame, MdxGrid, CodeInline }}>
          <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
      </article>
    </div>
  );
};

export default DailyTechQuestionsPage;

export const pageQuery = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        tags
        source
        date
        challengeLink
        difficulty
        description
      }
    }
    allSourceInfoJson {
      edges {
        node {
          name
          icon {
            childImageSharp {
              gatsbyImageData
            }
          }
          displayName
        }
      }
    }
  }
`;

const getSource = (data, mdxSource) => {
  const sourceList = data.allSourceInfoJson.edges;
  const matchedSource = sourceList.filter((source) => source.node.name === mdxSource)[0];
  return matchedSource.node;
};

const MetaSource = ({ source, image, url }) => {
  return (
    <div className={styles.meta_col}>
      {/* <span>🔗 From:</span> */}
      <span>🔗</span>
      <div className={styles.source_logo}>
        <GatsbyImage image={image} alt={source.name}></GatsbyImage>
      </div>
      <a href={url} className={styles.source_link}>
        {source.displayName}
      </a>
    </div>
  );
};

const MetaDifficulty = ({ difficulty }) => {
  return (
    <div className={styles.meta_col}>
      <span>📈</span>
      <div>{difficulty}</div>
    </div>
  );
};

const MetaDuration = () => {
  return (
    <div className={styles.meta_col}>
      <span>⏰</span>
      <p>20 mins</p>
    </div>
  );
};
